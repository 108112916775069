import { render, staticRenderFns } from "./MSPAP503M.vue?vue&type=template&id=7245ec35&scoped=true&"
import script from "./MSPAP503M.vue?vue&type=script&lang=js&"
export * from "./MSPAP503M.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7245ec35",
  null
  
)

export default component.exports