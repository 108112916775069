/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP503M
 * 화면설명: 신계약접수현황 조회
 * 접근권한: 지점장
 * 작 성 일: 2022.06.20
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="신계약접수현황" :show-title="true" type="subpage" action-type="search" @on-scroll-top="fn_ModalOpen" @action-search-click="fn_Open514Pop"
  :topButton="true">
    <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-info-sum" >
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 {{sum_contSpcfTot}}건</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container v-if="sum_contSpcfTot > 0" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-contract-list" >
          <mo-list :list-data="lv_ContSpcfList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents" @touchstart="fn_ModalClose">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.prdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="txt_right">
                      <span><strong>{{$commonUtil.numberWithCommas(item.smtotPrm.amount)}}</strong></span>
                    </div>
                  </div>
                  <div class="list-item__contents__info inlineBox">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtGray txtSkip mw115">계약자 <b>{{item.contrNm}}</b></span><em>|</em>
                      <span class="txtGray">발행일 {{fn_moment(lv_SearchData.searchDate)}}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box"></div>
                    <div class="right-box">
                      <span class="txtLine" @click="fn_Open511Pop(item)">{{item.clctCnsltNm}}({{item.clctCnsltNo}})</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <!-- NoData 영역 ::start  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list no-data">
          <mo-list-item>
            <div class="list-item__contents w100">
              <div class="list-item__contents__info w100">
                <span v-if="emptyFlag === 1">상세조회 조건을 설정해 주세요.</span>
                <span v-else>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 ::end  -->
      </ur-box-container>
    </ur-box-container>

    <!-- 바텀시트 영역 ::start  -->
    <div class="ns-bottom-sheet ns-bottom-popup-m ns0000003">
      <mo-bottom-sheet ref="refAttnPnstaModal">
          <template v-slot:title></template>
          <div class="ns-seminar-info">
            <ul>
              <li>
                <span>건수</span>
                <strong>{{$commonUtil.numberWithCommas(sum_contSpcfTot)}}건</strong>
              </li>
              <li>
                <span>합계보험료</span>
                <strong>{{$commonUtil.numberWithCommas(sum_mpadBomthPrm)}}원</strong>
              </li>
              <li>
                <span>환산성적</span>
                <strong>{{$commonUtil.numberWithCommas(sum_frstYrCnvlPrfmAmt)}}원</strong>
              </li>
            </ul>
          </div>
      </mo-bottom-sheet>
    </div>
    <!-- 바텀시트 영역 ::end  -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import apConstants from '@/config/constants/apConstants.js'
  import MSPAP514P from '@/ui/ap/MSPAP514P' // 상세조회 팝업
  import MSPAP511P from '@/ui/ap/MSPAP511P' // FC문자/전화 팝업

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    // 화면명
    name: "MSPAP503M",
    // 화면ID
    screenId: "MSPAP503M",
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      this.fn_Init()
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.fn_ModalOpen()
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_UserInfo: {}, // 사용자 정보
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        lv_IsFirst: true, // 최초조회 여부
        lv_ContSpcfList: [], // 신계약접수현황 리스트
        lv_ContSpcfListOrg: [],

        emptyFlag: 1, // 1: 데이터가 없습니다. 2: 검색결과가 없습니다

        lv_SearchData: {},

        lv_FofOrgData: [], // 지점 목록
        isDofRolYn: '',

        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: ''}], // NextKey 방식의 페이징에서 다음 페이지의 첫번째 항목의 Key값 저장(http.js에 선언된 변수명과 동일한 이름 사용)
        pageRowCnt: 99999, // 한 페이지에 노출되는 데이터 건수(http.js에 선언된 변수명과 동일한 이름 사용)

        lv_Sort: '',
        lv_SortList: [],
        lv_FofList: [],
        isLoading: false
      }
    },
    watch: {
      lv_IsFirst (value) {
        this.emptyFlag = value ? 1 : 2
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 합계보험료
      sum_mpadBomthPrm () {
        let ret = 0
        if (this.sum_contSpcfTot > 0) {
          this.lv_ContSpcfList.forEach(item => {
            ret += item.mpadBomthPrm.intAmount
          })
        }

        return ret
      },
      // 환산성적
      sum_frstYrCnvlPrfmAmt () {
        let ret = 0
        if (this.sum_contSpcfTot > 0) {
          this.lv_ContSpcfList.forEach(item => {
            ret += item.frstYrCnvlPrfmAmt.intAmount
          })
        }

        return ret
      },
      // 신계약접수현황 Length
      sum_contSpcfTot() {
        return this.lv_ContSpcfList.length
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * @function   fn_Init
      * @notice     화면 초기화
      ******************************************************************************/
      fn_Init () {
        this.lv_SortList = _.cloneDeep(apConstants.NCONT_ACPN_PNSTA_SORT_CD_LIST)
        console.log(this.lv_SortList)
        this.fn_ClearSet()

        this.isDofRolYn = this.lv_UserInfo.isDofRolYn // 로그인 사용자의 시스템 옵션 검색
        this.fn_GetFofRolCallBack()
      },
      /******************************************************************************
      * @function   fn_ClearSet
      * @notice     데이터 클리어
      ******************************************************************************/
      fn_ClearSet () {
        this.lv_ContSpcfList.slice(0)
        this.lv_ContSpcfList = []
        this.lv_ContSpcfListOrg.slice(0)
        this.lv_ContSpcfListOrg = []
        this.stndKeyList.slice(0)
        this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: ''}]
        this.stndKeyVal = ''
      },
      /******************************************************************************
      * @function   fn_SetParams
      * @notice     신계약접수현황 조회 파라미터 셋팅
      ******************************************************************************/
      fn_SetParams () {
        /** 지점 셋팅 케이스 **/
        // 1. 사업부 산하 전체 지점 (지점 리스트) dofOrg.key === 'FOF_ALL'
        // 2. 지역부 산하 전체 지점 (지점 리스트) fofOrg.key === '0'
        // 3. 특정 지점 (지점 한개) fofOrg.key !== '0'
        /** 특정 컨설턴트 선택 케이스 **/
        // 1. 해당 PO에서 전체 조회해온다
        // 2. 응답값 리스트에서 선택한 컨설턴트 사번으로 filter 한다.(fn_SortData)

        let pData = this.lv_SearchData
        let pInptYmd = pData.searchDate
        let pParams = {
            srchScCd: '1',    // 조회구분코드 (1:신계약목록, 2:청약서목록)
            slctYn: 'Y',      // 지사전체선택여부 (Y:전체지사선택, N:지사선택)
            rctmTrtScCd: '9', // 입금처리구분코드 (9:전체,  4:입금완료)
            inptYmd: moment(pInptYmd).format('YYYYMMDD'),
            ICClctOrgListVO: []
            // iCClctCnsltListVO: [] // 사번검색 [{clctCnsltNo: '0001057465'}, ... {clctCnsltNo: ''}] // 제공시스템(PQM)에서 전체조회와 동일
          }

        if (this.lv_IsFirst) {
          pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
          pParams.ICClctOrgListVO = []
          if (this.isDofRolYn === 'Y') {
            this.lv_FofOrgData.forEach(item => {
              pParams.ICClctOrgListVO.push({clctOrgNo: item.key})
            })
          } else {
            pParams.ICClctOrgListVO.push({clctOrgNo: this.lv_UserInfo.onpstFofOrgNo})
          }
        } else {
          // 조회할 지점 리스트 setting
          let fofList = this.lv_FofList.splice(0, 150)

          pParams.ICClctOrgListVO.splice(0, pParams.ICClctOrgListVO.length)
          pParams.ICClctOrgListVO = []
          if (this.isDofRolYn === 'Y') {
            if (fofList.length === 1) { // 특정 지점 (지점 한개)
              pParams.ICClctOrgListVO.push({clctOrgNo: fofList[0].key})
            } else { // 사업부 산하 전체 지점 || 지역부 산하 전체 지점
              if (!_.isEmpty(fofList)) {
                fofList.forEach(item => {
                  if (item.key !== '0') {
                    pParams.ICClctOrgListVO.push({clctOrgNo: item.key})
                  }
                })
              } else {
                if (pData.dofOrg.key !== 'FOF_ALL') {
                  // 선택 지점 없음
                  this.$addSnackbar('선택하신 지점을 다시 확인해주시길 바랍니다.')
                  return
                } else {
                  // 조회 완료
                }
              }
            }
          } else {
            pParams.ICClctOrgListVO.push({clctOrgNo: this.lv_UserInfo.onpstFofOrgNo})
          }
        }
        pParams.pageRowCnt = this.pageRowCnt
        pParams.stndKeyList = this.stndKeyList

        return pParams
      },
      /******************************************************************************
      * @function   fn_GetData
      * @notice     data 조회
      ******************************************************************************/
      fn_GetData () {
        const lv_Vm = this

        if (!this.isLoading) {
          this.fn_ClearSet()
        }

        let pParams = this.fn_SetParams()
        let commHeader = {
          eaiId: 'C341_F1PQL0327_S',
          fahrTrnId: 'S',
          requestSystemCode: 'F1341', // 교체필요
          resVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO',
          reqVONm: 'sli.in.ea2.vo.a0025pq.EAF1PQL0327VO'
        }

        this.isLoading = true
        lv_Vm.$pmUtil.eaiInvoke(pParams, 'txTSSPM01P1', 'S', commHeader, lv_Vm.fn_PMUtilHandler)
      },
      /******************************************************************************
      * @function   fn_PMUtilHandler
      * @notice     PM유틸 핸들러
      *             접수현황 없을경우, body null값으로, 조회할 지점이 남아있을경우를
      *             조건문으로 체크하여 잘라서 모든 지점 조회 수행
      ******************************************************************************/
      fn_PMUtilHandler (response) {
        const lv_Vm = this

        if (!_.isEmpty(response) && !_.isEmpty(response.body)) {
          let contSpcfList = response.body.icncontSpcfListVO

          if (!_.isEmpty(contSpcfList)) {
            contSpcfList.forEach(item => {
              item.acpnYmd = item.vuchNo.split('').reverse().join('')
              lv_Vm.lv_ContSpcfList.push(item)
            })
            Object.assign(lv_Vm.lv_ContSpcfListOrg, lv_Vm.lv_ContSpcfList)
          }          
        } else {
          lv_Vm.isLoading = false
        }

        // 조회할 조점 리스트가 없으면 더 조회하지 않음
        if (!_.isEmpty(lv_Vm.lv_FofList)) {
          lv_Vm.fn_GetData()
        } else {
          lv_Vm.isLoading = false
          if (!_.isEmpty(lv_Vm.lv_ContSpcfList)) {
            lv_Vm.fn_SortData()
          }
        }
      },
      /******************************************************************************
      * @function   fn_SortData
      * @notice     데이터 정렬
      ******************************************************************************/
      fn_SortData() {
        let value = this.lv_SearchData.priority[0] ? this.lv_SearchData.priority[0].key : ''
        if (value === '01') { // 보험료 높은
          value = '7'
        } else { // 02: 보험료 낮은
          value = '8'
        }

        let sortType = ''
        let sortObj = ''
        let sortList = []
        
        // 조회할 컨설턴트
        if (this.lv_SearchData.cnslt.key !== '0') {
          this.lv_ContSpcfList = this.lv_ContSpcfList.filter(o => o.clctCnsltNo === this.lv_SearchData.cnslt.key)
        }

        sortObj = this.lv_SortList.filter(item => item.value === value)[0]
        sortType = sortObj.type

        sortList = this.lv_ContSpcfList
        if (sortObj.sort === 'ASC') {
          sortList = sortList.sort((a, b) => (a[sortType]['intAmount'] < b[sortType]['intAmount']) ? 1 : -1);
        } else {
          sortList = sortList.sort((a, b) => (a[sortType]['intAmount'] > b[sortType]['intAmount']) ? 1 : -1);
        }

        if (!_.isEmpty(sortList)) {
          this.fn_ModalOpen()
        }
        this.lv_ContSpcfList = sortList
      },
      /******************************************************************************
      * @function   fn_Open511Pop
      * @notice     FC문자/전화 팝업 호출
      ******************************************************************************/
      fn_Open511Pop (item) {
        if (this.$bizUtil.isEmpty(item.clctCnsltNo)) {
          this.$addSnackbar('잠시후 다시 시도해 주세요.')
          return
        }
        let popupObj = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: item.clctCnsltNm,
            pCnsltNo: item.clctCnsltNo
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(popupObj)
            },
            onPopupClose: () => {
              this.$bottomModal.close(popupObj)
            }
          }
        })
      },
      /******************************************************************************
      * @function   fn_Open514Pop
      * @notice     상세조회조건 팝업 호출
      *             listeners: function 등록후, 팝업에서 emit시 실행됩니다.
      ******************************************************************************/
      fn_Open514Pop () {
        const lv_Vm = this

        let popupObj = {}
          popupObj = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: this.$options.screenId,
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                this.$bottomModal.close(popupObj)
                lv_Vm.lv_IsFirst = false
                Object.assign(lv_Vm.lv_SearchData, pData)

                if (!_.isEmpty(pData)) {
                  pData.FofOrgData = pData.FofOrgData.filter(item => item.key !== '0' || item.key !== 'FOF_ALL')
                  if (pData.fofOrg.key === '0' || pData.dofOrg.key === 'FOF_ALL') {
                    lv_Vm.lv_FofList = this.$bizUtil.cloneDeep(pData.FofOrgData)
                  } else {
                    lv_Vm.lv_FofList.push(pData.fofOrg)
                  }

                  lv_Vm.fn_GetData()
                }
              },
              onPopupClose: () => {
                this.$bottomModal.close(popupObj)
              }
            }
          })
      },
      /******************************************************************************
      * @function   fn_BottomAlert
      * @notice     확인 팝업 호출
      *             listeners: function 등록후, 팝업에서 emit시 실행됩니다.
      ******************************************************************************/
      fn_BottomAlert (contents) {
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      },
      
      /******************************************************************************
      * @function   fn_BackBtnClicked
      * @notice     백 버튼 클릭이벤트(Device Back 포함)
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (!this.lv_IsFirst) {
          this.lv_SearchData = {}
          this.lv_IsFirst = true
          this.fn_GetData()
        } else {
          this.$router.go(-1)
        }
      },
      /******************************************************************************
      * @function   fn_RoleBottomAlert
      * @notice     단순알림 팝업 호출
      ******************************************************************************/
      fn_RoleBottomAlert () {
        const lv_Vm = this

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '대리점을 먼저 선택해 주세요.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj);
              lv_Vm.fn_Open514Pop()
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj);
            }
          }
        },
        {properties: {preventTouchClose : false}}
      )},
      /******************************************************************************
      * @function   fn_GetFofRolCallBack
      * @notice     시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      *               {isEntpwRolYn[전사역할여부]=Y/N,
      *               isHofRolYn[사업부역할여부]=Y/N,
      *               isDofRolYn[지역단역할여부]=Y/N,
      *               isEofRolYn[지점역할여부]=Y/N}
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_GetFofRolCallBack () {
        if (this.isDofRolYn === 'Y') {
          this.lv_SearchParam.fofOrgNo = this.lv_UserInfo.onpstFofOrgNo
          this.lv_SearchParam.orgNo = this.lv_UserInfo.onpstFofOrgNo
          this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        } else {
          this.fn_GetData()
        }
      },
      /******************************************************************************
      * @function   fn_OrgSearchCallBack
      * @notice     시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_OrgSearchCallBack (rtnData) {
        this.lv_FofOrgData = rtnData[2]
        this.fn_GetData()
      },
      // 일자 표기
      fn_moment (date) {
        return moment(date).format('YYYY-MM-DD')
      },
      /******************************************************************************
      * @function   fn_ModalOpen / fn_ModalClose
      * @notice     신계약접수현황 건수 Modal Open/Close
      ******************************************************************************/
      fn_ModalOpen () {
        this.$refs.refAttnPnstaModal.open() 
      },
      fn_ModalClose () {
        this.$refs.refAttnPnstaModal.close() 
      },
    } // method end
  }
</script>
<style scoped>
</style>